<template>
	<li class="recursive">
		<template v-if="data.children">
			<span @click.capture="handlePrevent">
				<router-link :to="path" class="cursor row" align-y="center" align-x="between">
					<span>{{data.label}}</span>
					<i class="el-icon-caret-bottom el-icon--right" :class="{collapse}" />
				</router-link>
			</span>
			<el-collapse-transition>
				<ul v-show="!collapse">
					<template v-for="(item, i) in data.children">
						<recursive v-model="data.children[i]" :path="`${path}${item.path ? '/' + item.path : ''}`" :key="i" />
					</template>
				</ul>
			</el-collapse-transition>
		</template>
		<router-link v-else :to="path">{{data.label}}</router-link>
	</li>
</template>

<script>
export default {
	components: {
		Recursive: () => import("./Recursive")
	},
	props: {
		path: String,
		value: {
			type: Object,
			required: true
		}
	},
	model: {
		prop: "value",
		event: "change"
	},
	data() {
		return {
			collapse: true
		}
	},
	computed: {
		data: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("change", val);
			}
		},
	},
	methods: {
		handlePrevent(e) {
			e.preventDefault();
			this.collapse = !this.collapse
		}
	}
}
</script>

<style lang="scss" scoped>
li {
	list-style: none;
	cursor: pointer;
}
a,
.cursor {
	display: block;
	width: 100%;
	padding: 0.5em;
}
.el-icon-caret-bottom {
	transition: 0.5s;
	&.collapse {
		transform: rotate(-90deg);
	}
}
a:hover,
.cursor:hover,
.router-link-active {
	color: $blue;
	text-decoration: underline;
}
</style>